<template>
    <div>
        <div class="nav">
            <div class="logo" @click="routerJump('/home')">
                <img
                    v-show="blueLogo"
                    src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625640728284.png"
                    alt="blueLogo"
                />
                <img
                    v-show="!blueLogo"
                    src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625647714110.png"
                    alt="blackLogo"
                />
            </div>
            <ul class="nav-list">
                <li
                    v-for="item in navList"
                    :key="item.id"
                    @click="routerJump(item.path)"
                    :style="{ color: path === item.path ? '#26A1FF' : color }"
                >
                    {{ item.name }}
                </li>
            </ul>
        </div>
        <router-view></router-view>
        <div v-show="path !== '/login'" class="contact-us">
            <div class="contact-us-title">联系我们</div>
            <div class="contact-us-item">
                <span class="contact-us-left">客服热线</span
                ><span class="contact-us-right">4000-388-687</span>
            </div>
            <div class="contact-us-item">
                <span class="contact-us-left">官方邮箱</span
                ><span class="contact-us-right">moyuhuanqiu@163.com</span>
            </div>
            <div class="contact-us-item">
                <span class="contact-us-left">总部办公地址</span
                ><span class="contact-us-right"
                    >上海市宝山区真大路520号2幢329室</span
                >
            </div>
        </div>
        <div v-show="path !== '/login'" class="footer">
            <div class="footer-content">
                <div
                    @click="goKeepOnRecord(keepOnRecordObj.url)"
                    class="footer-content-left"
                >
                    {{ keepOnRecordObj.title }}
                </div>
                <div
                    class="footer-content-right"
                    @click="goKeepOnRecord(keepOnRecordObj.contentUrl)"
                >
                    <div
                        class="keep-on-record-icon"
                        :style="{
                            backgroundImage: `url(${keepOnRecordObj.picPath})`,
                        }"
                    ></div>
                    <span class="keep-on-record-number">{{
                        keepOnRecordObj.content
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { articleTagList } from "@/http/api";
export default {
    components: {},
    data() {
        return {
            path: "",
            blueLogo: false,
            color: "#fff",
            navList: [
                { id: 1, name: "首页", path: "/home" },
                { id: 2, name: "定制游", path: "/customTour" },
                { id: 4, name: "组队", path: "/goWith" },
                { id: 5, name: "热门路线", path: "/hotRoute" },
                { id: 6, name: "APP下载", path: "/appDownload" },
            ],
            keepOnRecordObj: {
                picPath:
                    "https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1661233212930.png",
                url: "https://beian.miit.gov.cn/",
                title: "备案号：沪ICP备18044950号-1",
                content: "沪公网安备 31011302005786号",
                contentUrl: "https://beian.mps.gov.cn/#/query/webSearch",
            },
        };
    },
    created() {
        // this.getArticleTagList();
    },
    mounted() {
        this.path = this.$route.path;
        this.logoType();
    },
    methods: {
        routerJump(path) {
            this.$router.push(path);
            this.logoType();
        },
        logoType() {
            if (
                this.path === "/travelCommunity" ||
                this.path === "/goWith" ||
                this.path === "/hotRoute" ||
                this.path === "/latestNews" ||
                this.path === "/travelArtcle"
            ) {
                this.blueLogo = true;
                this.color = "#000";
            } else {
                this.blueLogo = false;
                this.color = "#fff";
            }
        },
        // 获取备案信息
        async getArticleTagList() {
            const { data: res } = await articleTagList({
                nid: "os_keep_on_record",
            });
            if (res.code === 200 && res.data.length > 0) {
                if (!res.data[0].picPath.startsWith("http")) {
                    res.data[0].picPath = this.$oss + res.data[0].picPath;
                }
                this.keepOnRecordObj = {
                    picPath: res.data[0].picPath,
                    url: res.data[0].introduction,
                    title: res.data[0].title,
                    content: res.data[0].content,
                };
            }
        },
        // 打开备案网站
        goKeepOnRecord(url) {
            console.log(url);
            open(url);
        },
    },
    watch: {
        $route(route) {
            this.path = route.path;
            this.logoType();
        },
    },
};
</script>

<style scope lang="scss">
.nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 120px;
    width: 100%;
    height: 122px;
    z-index: 999;
    .logo {
        cursor: pointer;
        width: 139px;
        height: 48px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .nav-list {
        display: flex;
        margin: 0;
        padding: 0;
        height: 42px;
        li {
            display: flex;
            align-items: center;
            margin-right: 48px;
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
            .nav-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 118px;
                height: 42px;
                border: 0;
                border-radius: 23px;
                color: #fff;
                background: linear-gradient(
                    139deg,
                    #35a4ff 0%,
                    #57cdff 55%,
                    #5ad0fe 100%
                );
            }
        }
    }
}
.contact-us {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    margin-bottom: 58px;
    height: 388px;
    .contact-us-title {
        margin-bottom: 58px;
        font-size: 28px;
        font-weight: 800;
        color: #333;
    }
    .contact-us-item {
        display: flex;
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
        .contact-us-left {
            margin-right: 42px;
            font-size: 20px;
            font-weight: 400;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #999999;
        }
        .contact-us-right {
            font-size: 20px;
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 600;
            color: #333333;
        }
    }
}
.footer {
    overflow: hidden;
    height: 188px;
    background: #063b63;
    .footer-content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 133px;
        font-size: 14px;
        color: #fff;
        font-family: PingFangSC-Regular, PingFang SC;
        .footer-content-left {
            cursor: pointer;
        }
        .footer-content-right {
            display: flex;
            align-items: center;
            margin-left: 63px;

            .keep-on-record-icon {
                margin-right: 8px;
                width: 20px;
                height: 20px;
                background-size: 100% 100%;
                cursor: pointer;
            }
            .keep-on-record-number {
                cursor: pointer;
            }
        }
    }
}
</style>
