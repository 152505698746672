<template>
    <div class="home-header">
        <div class="home-header-container">
            <img
                class="logo"
                :src="logoImg"
                @click="routerJump('/home')"
            />
            <div class="menu">
                <div
                    v-for="(menu, index) in menuList"
                    :key="index"
                    :class="
                        menu.path === path
                            ? 'active-menu-item menu-item'
                            : 'menu-item'
                    "
                    @click="routerJump(menu.path)"
                >
                    {{ menu.name }}
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import logoImg from '@/assets/images/mobile/logo-blue.png'
export default {
    watch: {
        $route(route) {
            this.path = route.path;
        },
    },
    data() {
        return {
            menuList: [
                { id: 1, name: "首页", path: "/home" },
                { id: 2, name: "定制游", path: "/customTour" },
                { id: 4, name: "组队", path: "/goWith" },
                { id: 5, name: "热门路线", path: "/hotRoute" },
                { id: 6, name: "APP下载", path: "/appDownload" },
            ],
            path: this.$route.path,
            logoImg: logoImg
        };
    },
    methods: {
        routerJump(path) {
            this.$router.push(path);
            this.path = path;
        },
    },
};
</script>
<style scoped lang="scss">
.home-header {
    z-index: 1000;
    width: 100%;
    background-color: #ffffff;
    color: #3d3d3d;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08),
        0px 0px 100px 5px rgba(0, 0, 0, 0.16);

    .home-header-container {
        max-width: 1440px;
        padding: 15px;
        box-sizing: border-box;
        margin: 0 auto;
        z-index: 1000;
        box-sizing: border-box;
        .logo {
            width: 91px;
        }
        .menu {
            font-size: 14px;
            display: flex;
            justify-content: space-around;
            padding: 20px 0;
            .menu-item {
                color: #3d3d3d;
                font-weight: bold;
            }
            .active-menu-item {
                color: rgb(38, 161, 255);
            }
        }
    }
}
</style>
